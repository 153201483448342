import { curry } from 'lodash';
import { CopyEntityType } from './contants.js';

/**
 * @param {{id, type}} item
 * @returns {`${string}_${string}`}
 */
export const getOpenStructureItemKey = ({ type, id }) => `${type}_${id}`;

/**
 * @param {{ id, type }|null|undefined} entity1
 * @param {{ id, type }|null|undefined} entity2
 * @returns {boolean}
 */
export const isEntityEqual = (entity1, entity2) => (
    Boolean(entity1) &&
    Boolean(entity2) &&
    Number(entity1.id) === Number(entity2.id) &&
    String(entity1.type) === String(entity2.type)
);

export const isEntityEqualCurried = curry(isEntityEqual);

/**
 * @param {*[]} modules
 * @param {string[]} openedStructure
 * @param {Array<{ id, type }>} selectedStructure
 * @returns {*[]}
 */
export const getStructureItemsFromModules = (modules, openedStructure, selectedStructure) => modules?.map(module => {
    /**
     * @param {'module'|'section'|'page'} type
     * @param {string|number} id
     * @returns {boolean}
     */
    const isOpened = (type, id) => openedStructure.includes(getOpenStructureItemKey({
        type,
        id,
    }));

    /**
     * @param {'module'|'section'|'page'} type
     * @param {string|number} id
     * @returns {boolean}
     */
    const isSelected = (type, id) => selectedStructure?.some(isEntityEqualCurried({
        type,
        id,
    })) ?? false;

    const getPagesItems = pages => pages?.map(page => ({
        id: page.id,
        type: CopyEntityType.PAGE,
        control: 'checkbox',
        label: page.name,
        opened: isOpened(CopyEntityType.PAGE, page.id),
        checked: isSelected(CopyEntityType.PAGE, page.id),
    }));

    const sections = module.sections?.map(section => ({
        id: section.id,
        type: CopyEntityType.SECTION,
        control: 'checkbox',
        label: section.title,
        opened: isOpened(CopyEntityType.SECTION, section.id),
        checked: isSelected(CopyEntityType.SECTION, section.id),
        children: getPagesItems(section.pages) ?? [],
    }));

    const pages = getPagesItems(module.pages);

    const getItems = items => items?.length && items;

    return {
        id: module.id,
        type: CopyEntityType.MODULE,
        label: module.title,
        control: 'checkbox',
        opened: isOpened(CopyEntityType.MODULE, module.id),
        checked: isSelected(CopyEntityType.MODULE, module.id),
        children: getItems(sections) || getItems(pages) || [],
    };
}) ?? [];
