
import markdownit from 'markdown-it';
import * as api from '@/api/AiApi.js';
import { getEchoInstance } from '@/services/echo.js';
import useSnackbar from '@/hooks/snackbars.js';
import { AiSessionTypes } from '@/constants/AI.js';
import { showErrorSnackbar } from '@/utils/http.js';

/**
 * @type {import('markdown-it').default|null}
 */
let mdRenderer = null;
const getRenderMarkdownResult = result => {
    if (!mdRenderer) {
        mdRenderer = markdownit({
            html: true,
            linkify: true,
        });

        // Использовать только h4 для всех заголовков
        // eslint-disable-next-line max-params
        mdRenderer.renderer.rules.heading_open = (tokens, idx, options, env, renderer) => {
            tokens[idx].tag = 'h4';

            return renderer.renderToken(tokens, idx, options);
        };
        // eslint-disable-next-line max-params
        mdRenderer.renderer.rules.heading_close = (tokens, idx, options, env, renderer) => {
            tokens[idx].tag = 'h4';

            return renderer.renderToken(tokens, idx, options);
        };
    }

    return mdRenderer.render(result);
};
const { createSnackbar } = useSnackbar();

export default {
    namespaced: true,
    state: {
        isShowModal: false,
        loading: false,
        error: false,
        searchQuery: '',
        prevSearchQuery: '',
        searchPromptType: null,
        searchResult: '',
        searchResultLinks: [],
        incorrectAnswers: [],
        aiSessionId: null,
        currentAiMessageId: null,
        socketInstasnce: null,
        currentActivityId: null,
        currentEntityId: null,
        editorInstance: null,
        /**
         * @type {string|null}
         */
        sessionType: null,
        /**
         * @type {string|null}
         */
        sessionEntityId: null,
    },
    getters: {
        isShowModal: state => state.isShowModal,
        isLoading: state => state.loading,
        searchQuery: state => state.searchQuery,
        prevSearchQuery: state => state.prevSearchQuery,
        searchPromptType: state => state.searchPromptType,
        searchResult: state => state.searchResult,
        searchResultLinks: state => state.searchResultLinks,
        aiSessionId: state => state.aiSessionId,
        currentAiMessageId: state => state.currentAiMessageId,
        socketInstance: state => state.socketInstance,
        currentActivityId: state => state.currentActivityId,
        editorInstance: state => state.editorInstance,
    },
    mutations: {
        setShowModal(state, value) {
            state.isShowModal = value;
        },
        setLoading(state, value) {
            state.loading = value;
        },
        setError(state, value) {
            state.error = value;
        },
        setSearchQuery(state, value) {
            state.searchQuery = value;
        },
        setPrevSearchQuery(state, value) {
            state.prevSearchQuery = value;
        },
        setSearchPromptType(state, value) {
            state.searchPromptType = value;
        },
        setSearchResult(state, value) {
            state.searchResult = value;
        },
        setSearchResultLinks(state, value) {
            state.searchResultLinks = value;
        },
        setIncorrectAnswers(state, value = []) {
            state.incorrectAnswers = value;
        },
        setAiSessionId(state, value) {
            state.aiSessionId = value;
        },
        setCurrentAiMessageId(state, value) {
            state.currentAiMessageId = value;
        },
        setSocketInstance(state, value) {
            state.socketInstance = value;
        },
        setCurrentActivityId(state, value) {
            state.currentActivityId = value;
        },
        setCurrentEntityId(state, value) {
            state.currentActivityId = value;
        },
        setEditorInstance(state, value) {
            state.editorInstance = value;
        },
        /**
         * @param {*} state
         * @param {{ type: string, entity_id?: string|number|null }} payload
         */
        setSessionData(state, payload) {
            const { type = AiSessionTypes.TEXT_BLOCK, entity_id = null } = payload;

            state.sessionType = type;
            state.sessionEntityId = entity_id;
        },
        /**
         * @param {*} state
         */
        clearSessionData(state) {
            state.sessionType = null;
            state.sessionEntityId = null;
        },
    },
    actions: {
        async createAiSession({ commit, getters, dispatch }, activityId) {
            try {
                const { currentActivityId } = getters;

                if (activityId === currentActivityId && getters.socketInstance) return;

                commit('setLoading', true);
                commit('setError', false);

                if (getters.socketInstance) dispatch('stopAiSocketConnection');

                const { data } = await api.createAiSession(activityId);
                const sessionId = data.data.id;
                const prevSessionId = getters.aiSessionId;

                if (prevSessionId === sessionId) return;

                dispatch('startAiSocketConnection', sessionId);
                commit('setAiSessionId', sessionId);
                commit('setCurrentActivityId', activityId);
            } catch (err) {
                showErrorSnackbar(err);
                commit('setError', true);
                commit('setShowModal', false);
                throw err;
            } finally {
                commit('setLoading', false);
            }
        },
        async startAiSession({ commit, state, getters, dispatch }) {
            try {
                if (state.sessionEntityId === state.currentEntityId && getters.socketInstance) return;

                commit('setLoading', true);
                commit('setError', false);

                if (getters.socketInstance) dispatch('stopAiSocketConnection');

                const { data } = await api.createAiSession(state.sessionEntityId, state.sessionType);
                const sessionId = data.data.id;
                const prevSessionId = getters.aiSessionId;

                if (prevSessionId === sessionId) return;

                dispatch('startAiSocketConnection', sessionId);
                commit('setAiSessionId', sessionId);
                commit('setCurrentEntityId', state.sessionEntityId);
            } catch (err) {
                showErrorSnackbar(err);
                commit('setError', true);
                commit('setShowModal', false);
                throw err;
            } finally {
                commit('setLoading', false);
            }
        },
        startAiSocketConnection({ commit, dispatch }, sessionId) {
            const socketInstance = getEchoInstance()
                .private(`ai.session.${sessionId}`)
                .stopListening('.ai.session')
                .listen('.ai.session', data => {
                    const result = data.response.text || '';
                    const incorrectAnswers = data.response.incorrect_answers;
                    const messageId = data.message_id;

                    if (incorrectAnswers) {
                        dispatch('updateAiQuerySearchResult', {
                            result: result || `Вот несколько объемных, но совершенно неверных определений для машинного
                             обучения. Эти ответы явно далеки от реальности, но выглядят достаточно научно,
                             чтобы ввести кого-то в заблуждение.`,
                            messageId,
                            incorrectAnswers,
                        });
                    } else {
                        dispatch('updateAiQuerySearchResult', {
                            result,
                            messageId,
                        });
                    }
                })
                .stopListening('.ai.session_failed')
                .listen('.ai.session_failed', ({ response }) => {
                    console.error('ошибка отправления собщения в AI', response.error);
                    createSnackbar({
                        type: 'error',
                        message: 'Ошибка. Попробуйте еще раз',
                    });
                    dispatch('toggleShowAiModal', false);
                });

            commit('setSocketInstance', socketInstance);
        },
        stopAiSocketConnection({ commit, getters }) {
            const { socketInstance } = getters;

            if (!socketInstance) return;

            socketInstance.stopListening('.ai.session').stopListening('.ai.session_failed');

            commit('setSocketInstance', null);
            commit('setCurrentActivityId', null);
        },
        async sendAiSearchQuery({ commit, getters }, { query, type }) {
            if (getters.isLoading) return;

            commit('setLoading', true);
            commit('setError', false);
            commit('setSearchQuery', query);
            commit('setPrevSearchQuery', query);
            commit('setSearchPromptType', type);

            try {
                const { data } = await api.sendAiSearchQuery(getters.aiSessionId, {
                    text: query,
                    prompt_type: type,
                });

                const messageId = data.data.id;

                commit('setCurrentAiMessageId', messageId);

            } catch (err) {
                console.error(err);
                commit('setLoading', false);
                commit('setError', true);
                throw err;
            }
        },
        updateAiQuerySearchResult({ commit }, { result, messageId, incorrectAnswers }) {
            try {
                const searchResult = getRenderMarkdownResult(result);

                commit('setSearchQuery', '');
                commit('setSearchResult', searchResult);
                commit('setIncorrectAnswers', incorrectAnswers);
                commit('setCurrentAiMessageId', messageId);
            } catch (err) {
                console.error(err);
            } finally {
                commit('setLoading', false);
            }
        },
        toggleShowAiModal({ commit }, isShowModal) {
            commit('setShowModal', isShowModal);
        },
        setSearchPromptType({ commit }, value) {
            commit('setSearchPromptType', value);
        },
        /**
         * @param {*} ctx
         * @param {{
         *      query_text?: string,
         *      query_type?: import('@/constants/AI.js').AiMessagesPromptTypes | null,
         *      session_type?: import('@/constants/AI.js').AiSessionTypes
         * }} payload
         */
        showAiModal({ commit, dispatch }, payload) {
            const {
                query_text = '',
                query_type = null,
                session_type = AiSessionTypes.TEXT_BLOCK,
                session_entity_id = null,
            } = payload;

            dispatch('setSearchQuery', query_text);
            dispatch('setSearchPromptType', query_type);
            commit('setSessionData', {
                type: session_type,
                entity_id: session_entity_id,
            });
            commit('setShowModal', true);
        },
        async canselSearchResult({ commit, dispatch }, data) {
            try {
                await api.canselMeassage(data);
                commit('setLoading', false);
                dispatch('updateAiQuerySearchResult', {
                    result: '',
                    messageId: null,
                    incorrectAnswers: [],
                });
            } catch (error) {
                console.error(error);
            }
        },
        setEditorInstance({ commit }, editor) {
            commit('setEditorInstance', editor);
        },
        setCurrentActivityId({ commit }, value) {
            commit('setCurrentActivityId', value);
        },
        setSearchQuery({ commit }, value) {
            commit('setSearchQuery', value);
            commit('setPrevSearchQuery', value);
        },
    },
};
