<template>
    <HashScrollComponent>
        <div class="App">
            <ErrorLayout v-if="isGlobalError" />
            <div
                v-else-if="userLoading"
                class="app-loader">
                <span class="loader"></span>
            </div>
            <DefaultLayout v-else />
        </div>
        <Confirmation />
        <CookiesPopup />
    </HashScrollComponent>
</template>

<script>
import { createNamespacedHelpers } from 'vuex';
import _throttle from 'lodash/throttle.js';
import { userGetterTypes } from '@/store/modules/user.js';
import YandexMetrika from '@/mixins/YandexMetrika.js';
import DefaultLayout from '@/layouts/DefaultLayout.vue';
import ErrorLayout from '@/layouts/ErrorLayout.vue';
import Confirmation from '@/components/Confirmation.vue';
import CookiesPopup from '@/components/CookiesPopup/CookiesPopup.vue';
import HashScrollComponent from '@/components/HashScrollComponent.vue';

const {
    mapGetters: mapUserGetters,
} = createNamespacedHelpers('user');
const RESIZE_THROTTLE_MS = 150;
const SCROLL_THROTTLE_MS = 300;
const BASE_SCROLLBAR_WIDTH = 5;
let lastScrollTop = 0;

export default {
    name: 'App',
    components: {
        Confirmation,
        DefaultLayout,
        ErrorLayout,
        HashScrollComponent,
        CookiesPopup,
    },
    mixins: [YandexMetrika],
    computed: {
        ...mapGetters({
            isGlobalError: 'globals/isGlobalError',
            scrollDirection: 'globals/scrollDirection',
            scrollDirections: 'globals/scrollDirections',
            scrollPosition: 'globals/scrollPosition',
            scrollPositions: 'globals/scrollPositions',
        }),
        ...mapUserGetters({
            user: userGetterTypes.GET_USER,
            userLoading: userGetterTypes.GET_USER_LOADING,
        }),
    },
    methods: {
        ...mapActions({
            setWinWidth: 'globals/setWinWidth',
            setScrollbarWidth: 'globals/setScrollbarWidth',
            setScrollDirection: 'globals/setScrollDirection',
            setScrollPosition: 'globals/setScrollPosition',
        }),
        setGlobals() {
            this.setWinWidth(window.innerWidth);

            const scrollbarWidth = this.$utils.getScrollbarWidth();

            this.setScrollbarWidth(scrollbarWidth);
            document.documentElement.style.setProperty(
                '--scrollbar-width',
                `${scrollbarWidth || BASE_SCROLLBAR_WIDTH}px`,
            );
        },
        calcScrollDirection() {
            const scrollDirection = window.pageYOffset > lastScrollTop
                ? this.scrollDirections.DOWN
                : this.scrollDirections.UP;

            if (scrollDirection !== this.scrollDirection) {
                this.setScrollDirection(scrollDirection);
            }

            lastScrollTop = window.pageYOffset;
        },
        calcScrollPosition() {
            const {
                scrollHeight,
                clientHeight,
            } = document.documentElement;

            let scrollPosition = this.scrollPositions.MIDDLE;

            if (window.pageYOffset <= 0) {
                scrollPosition = this.scrollPositions.TOP;
            } else if (window.pageYOffset + clientHeight >= scrollHeight) {
                scrollPosition = this.scrollPositions.BOTTOM;
            }

            if (scrollPosition !== this.scrollPosition) {
                this.setScrollPosition(scrollPosition);
            }
        },
        windowResizeHandler() {
            this.setGlobals();
        },
        windowScrollHandler() {
            this.calcScrollDirection();
            this.calcScrollPosition();
        },
    },
    created() {
        this.windowResizeHandler = _throttle(
            this.windowResizeHandler,
            RESIZE_THROTTLE_MS,
        );
        this.windowScrollHandler = _throttle(
            this.windowScrollHandler,
            SCROLL_THROTTLE_MS,
        );
    },
    mounted() {
        this.setGlobals();
        this.calcScrollPosition();

        window.addEventListener('resize', this.windowResizeHandler);
        window.addEventListener('orientationchange', this.windowResizeHandler);
        window.addEventListener('scroll', this.windowScrollHandler);
    },
    beforeUnmount() {
        window.removeEventListener('resize', this.windowResizeHandler);
        window.removeEventListener('orientationchange', this.windowResizeHandler);
        window.removeEventListener('scroll', this.windowScrollHandler);
    },
};
</script>

<style lang="scss">
.App {
    display: flex;
    flex-direction: column;
    scroll-behavior: smooth;
    min-height: 100vh;
}

.App__Content {
    max-height: 100vh;
}

.app-loader {
    position: fixed;
    top: 0;
    left: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100vw;
    height: 100vh;
}

.loader {
    display: inline-block;
    box-sizing: border-box;
    width: 48px;
    height: 48px;
    border: 5px solid #f2f2f2;
    border-bottom-color: #21ba72;
    border-radius: 50%;
    animation: rotation 1s linear infinite;
}

@keyframes rotation {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}
</style>
