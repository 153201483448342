import { CopyEntityType } from '@/admin/components/VersionCopy/VersionCopyMaterialsModal/contants.js';

export const solutionsGetterTypes = Object.freeze({
    GET_SOLUTIONS_ACTIONS: '[solutions] get solutions actions',
    GET_SOLUTIONS: '[solutions] get solutions list',
    GET_SOLUTION_ITEM: '[solutions] get solution item',
    GET_SOLUTIONS_STATUS_LIST: '[solutions] get solutions status list',
    GET_LOADING: '[solutions] get loading',
    GET_ERROR: '[solutions] get error',
    GET_CURRENT_PAGE: '[solutions] get current page',
    GET_IS_LAST_PAGE: '[solutions] get is last page',
    GET_LAST_PAGE: '[solutions] get last page',
    GET_COUNTS: '[solutions] get counts',
    GET_SOLUTION_REMOVED_INDEX: '[solutions] get solution removed index',
    GET_REMOVED_VERSION_INDEX: '[solutions] get version removed index',
    GET_ACCESS_DATA_FOR_RESTORE: '[solutions] get access data for restore',
    GET_CURRENT_PROJECT: '[solutions] get current project',
    GET_VERSIONS: '[solution] get versions',
    GET_VERSIONS_COUNT: '[solution] get versions count',
    GET_VERSIONS_CURRENT_PAGE: '[solution] get versions current page',
    GET_VERSIONS_LAST_PAGE: '[solution] get versions last page',
    GET_VERSIONS_TOTAL_COUNT: '[solutions] get versions total count',
    GET_VERSIONS_ERROR: '[solution] get versions error',
    GET_VERSIONS_LOADING: '[solution] get versions loading',
    GET_VERSION: '[solution] get version',
    GET_VERSION_LOADING: '[solution] get version loading',
    GET_VERSION_HOMEWORK_ACTIVITIES: '[solution] get solution homeworks activities',
    GET_VERSION_HOMEWORK_ACTIVITIES_TOTAL: '[solution] get solution homeworks activities total amount',
    GET_ACCESS_DENIED: '[solution] get acess denied',
    GET_IS_DEFAULT_SOLUTION_FILTERS: '[solution] get default solution filters status',
});

export const solutionsGetters = {
    [solutionsGetterTypes.GET_SOLUTIONS_ACTIONS]: state => state.solutionsActions,
    [solutionsGetterTypes.GET_SOLUTIONS]: state => state.solutions,
    [solutionsGetterTypes.GET_SOLUTIONS_STATUS_LIST]: state => state.solutionsStatusList,
    [solutionsGetterTypes.GET_SOLUTION_ITEM]: state => id => state.solutions.find(item => item.id === id),
    [solutionsGetterTypes.GET_LOADING]: state => state.loading,
    [solutionsGetterTypes.GET_ERROR]: state => state.error,
    [solutionsGetterTypes.GET_CURRENT_PAGE]: state => state.currentPage,
    [solutionsGetterTypes.GET_IS_LAST_PAGE]: state => state.currentPage >= state.lastPage,
    [solutionsGetterTypes.GET_LAST_PAGE]: state => state.lastPage,
    [solutionsGetterTypes.GET_COUNTS]: state => state.counts,
    [solutionsGetterTypes.GET_SOLUTION_REMOVED_INDEX]: state => state.removedSolutionIndex,
    [solutionsGetterTypes.GET_REMOVED_VERSION_INDEX]: state => state.removedVersionIndex,
    [solutionsGetterTypes.GET_ACCESS_DATA_FOR_RESTORE]: state => state.accessDataForRestore,
    [solutionsGetterTypes.GET_CURRENT_PROJECT]: state => state.currentProject,
    [solutionsGetterTypes.GET_VERSIONS]: state => state.versions,
    [solutionsGetterTypes.GET_VERSIONS_COUNT]: state => state.versionsCounts,
    [solutionsGetterTypes.GET_VERSIONS_CURRENT_PAGE]: state => state.currentVersionPage,
    [solutionsGetterTypes.GET_VERSIONS_LAST_PAGE]: state => state.versionsLastPage,
    [solutionsGetterTypes.GET_VERSIONS_TOTAL_COUNT]: state => state.versionsTotalCount,
    [solutionsGetterTypes.GET_VERSIONS_ERROR]: state => state.versionsError,
    [solutionsGetterTypes.GET_VERSIONS_LOADING]: state => state.vesionsLoading,
    [solutionsGetterTypes.GET_VERSION]: state => state.version,
    [solutionsGetterTypes.GET_VERSION_LOADING]: state => state.versionLoading,
    [solutionsGetterTypes.GET_VERSION_HOMEWORK_ACTIVITIES]: state => state.versionHomeworksActivities,
    [solutionsGetterTypes.GET_VERSION_HOMEWORK_ACTIVITIES_TOTAL]: state => state.versionHomeworksActivitiesTotal,
    [solutionsGetterTypes.GET_ACCESS_DENIED]: state => state.accessDenied,
    [solutionsGetterTypes.GET_IS_DEFAULT_SOLUTION_FILTERS]: state => state.IsDefaultSolutionFilters,

    destSource: state => {
        const { versionId } = state.copyVersion;
        const { selected } = state.copyVersionStructure;

        return [
            {
                id: versionId,
                type: CopyEntityType.VERSION,
            },
            ...selected,
        ];
    },

    destCurrent: state => {
        if (!state.copyVersion?.projectId) return null;

        return {
            id: state.copyVersion?.projectId,
            type: CopyEntityType.SOLUTION,
        };
    },

    destCopy: (state, getters) => state.copyDest || getters.destCurrent,
};
