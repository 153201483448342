import axios from 'axios';
import { BASE_URI_DEVELOPMENT_CENTER } from '@/api/CONST.js';

const prefix = BASE_URI_DEVELOPMENT_CENTER;

export const getCopyDestination = source => axios.post(`${prefix}/copy/destination`, {
    source,
});

/**
 * @param {{ source: Array<*>, destination: object }} data
 * @returns {Promise<AxiosResponse<any>>}
 */
export const sendCopyStructure = data => axios.post(`${prefix}/copy`, data);
