import uniqBy from 'lodash/uniqBy.js';
import { isEntityEqualCurried } from '@/admin/components/VersionCopy/VersionCopyMaterialsModal/utils.js';

export const solutionsMutationTypes = Object.freeze({
    SET_SOLUTIONS_ACTIONS: '[solutions] set solutions action',
    SET_SOLUTIONS_LIST: '[solutions] set solutions list',
    SET_SOLUTIONS_STATUS_LIST: '[solutions] set solutions status list',
    SET_LOADING: '[solutions] set loading',
    SET_ERROR: '[solutions] set error',
    SET_CURRENT_PAGE: '[solutions] increment current page',
    SET_LAST_PAGE: '[solutions] set last page',
    SET_COUNTS: '[solutions] set counts',
    SET_REMOVED_SOLUTION_INDEX: '[solutions] set removed solution index',
    SET_REMOVED_VERSION_INDEX: '[solutions] set version removed index',
    SET_ACCESS_DATA_FOR_RESTORE: '[solutions] set access data for restore',
    RESET_STATE: '[solutions] reset solutions state',
    SET_CURRENT_PROJECT: '[solution] set current project',
    SET_VERSIONS: '[solution] set versions list',
    SET_VERSIONS_COUNTS: '[solution] set versions counts',
    SET_VERSIONS_CURRENT_PAGE: '[solution] set versions current page',
    SET_VERSIONS_LAST_PAGE: '[solution] set versions last page',
    SET_VERSIONS_TOTAL_COUNT: '[solutions] set versions count',
    SET_VERSIONS_ERROR: '[solution] set versions error',
    SET_VERSIONS_LOADING: '[solution] set versions loading',
    RESET_VERSIONS_STATE: '[solution] reset versions state',
    SET_VERSION: '[solution] set version',
    SET_VERSION_LOADING: '[solution] set version loading',
    SET_VERSION_HOMEWORK_ACTIVITIES: '[solution] set homework activities',
    SET_VERSION_HOMEWORK_ACTIVITIES_TOTAL: '[solution] set homework activities total amount',
    SET_ACCESS_DENIED: '[solution] set access denied',
    SET_IS_DEFAULT_SOLUTION_FILTERS: '[solution] set is default solution filters status',
});

export const solutionsMutations = {
    [solutionsMutationTypes.SET_SOLUTIONS_ACTIONS](state, actions) {
        state.solutionsActions = actions;
    },
    [solutionsMutationTypes.SET_SOLUTIONS_LIST](state, solutions) {
        state.solutions = solutions;
    },
    [solutionsMutationTypes.SET_SOLUTIONS_STATUS_LIST](state, data) {
        state.solutionsStatusList = data;
    },
    [solutionsMutationTypes.SET_CURRENT_PAGE](state, page) {
        state.currentPage = page;
    },
    [solutionsMutationTypes.SET_IS_DEFAULT_SOLUTION_FILTERS](state, page) {
        state.IsDefaultSolutionFilters = page;
    },
    [solutionsMutationTypes.SET_LAST_PAGE](state, lastPage) {
        state.lastPage = lastPage;
    },
    [solutionsMutationTypes.SET_VERSIONS_TOTAL_COUNT](state, count) {
        state.versionsTotalCount = count;
    },
    [solutionsMutationTypes.SET_VERSION_HOMEWORK_ACTIVITIES](state, data) {
        state.versionHomeworksActivities = data;
    },
    [solutionsMutationTypes.SET_VERSION_HOMEWORK_ACTIVITIES_TOTAL](state, data) {
        state.versionHomeworksActivitiesTotal = data;
    },
    [solutionsMutationTypes.SET_LOADING](state, loading) {
        state.loading = loading;
    },
    [solutionsMutationTypes.SET_ERROR](state, error) {
        state.error = error;
    },
    [solutionsMutationTypes.SET_COUNTS](state, counts) {
        state.counts = counts;
    },
    [solutionsMutationTypes.SET_REMOVED_SOLUTION_INDEX](state, index) {
        state.removedSolutionIndex = index;
    },
    [solutionsMutationTypes.SET_REMOVED_VERSION_INDEX](state, index) {
        state.removedVersionIndex = index;
    },
    [solutionsMutationTypes.SET_ACCESS_DATA_FOR_RESTORE](state, data) {
        state.accessDataForRestore = data;
    },
    [solutionsMutationTypes.RESET_STATE](state) {
        const defaultState = {
            solutions: [],
            counters: {},
            currentPage: 0,
            lastPage: 1,
            error: '',
            loading: false,
            counts: {},
            removedSolutionIndex: 0,
            removedVersionIndex: 0,
            accessDataForRestore: null,
        };

        for (const key in defaultState) {
            if (Object.prototype.hasOwnProperty.call(defaultState, key)) {
                state[key] = defaultState[key];
            }
        }
    },
    [solutionsMutationTypes.SET_CURRENT_PROJECT](state, data) {
        state.currentProject = data;
    },
    [solutionsMutationTypes.SET_VERSIONS](state, data) {
        state.versions = data;
    },
    [solutionsMutationTypes.SET_VERSIONS_COUNTS](state, data) {
        state.versionsCounts = data;
    },
    [solutionsMutationTypes.SET_VERSIONS_CURRENT_PAGE](state, data) {
        state.currentVersionPage = data;
    },
    [solutionsMutationTypes.SET_VERSIONS_LAST_PAGE](state, data) {
        state.versionsLastPage = data;
    },
    [solutionsMutationTypes.SET_VERSIONS_ERROR](state, data) {
        state.versionsError = data;
    },
    [solutionsMutationTypes.SET_VERSIONS_LOADING](state, data) {
        state.vesionsLoading = data;
    },
    [solutionsMutationTypes.RESET_VERSIONS_STATE](state) {
        const defaultState = {
            currentProject: null,
            versions: [],
            versionsCounts: {},
            currentVersionPage: 0,
            versionsLastPage: 1,
            versionsTotalCount: 0,
            versionsError: '',
            vesionsLoading: false,
        };

        for (const key in defaultState) {
            if (Object.prototype.hasOwnProperty.call(defaultState, key)) {
                state[key] = defaultState[key];
            }
        }
    },
    [solutionsMutationTypes.SET_VERSION](state, data) {
        state.version = data;
    },
    [solutionsMutationTypes.SET_VERSION_LOADING](state, loading) {
        state.versionLoading = loading;
    },
    [solutionsMutationTypes.SET_ACCESS_DENIED](state, payload) {
        state.accessDenied = payload;
    },


    /**
     * @param state
     * @param {{ versionId, projectId }} payload
     */
    setCopyVersion(state, payload) {
        state.copyVersion = payload;
    },

    /**
     * @param state
     * @param {boolean} value
     */
    loadingCopyVersionStructure(state, value) {
        state.copyVersionStructure.loading = value;
    },

    saveCopyVersionStructure(state, payload) {
        state.copyVersionStructure.data = payload;
    },

    hideCopyVersion(state) {
        state.copyVersion = null;
    },

    clearCopySelected(state) {
        state.copyDest = null;
        state.copyVersionStructure.selected = [];
    },

    setCopySelected(state, payload) {
        state.copyVersionStructure.selected = payload;
    },

    /**
     * @param {*} state
     * @param {Array<{type, id}>} payload
     */
    selectCopyStructureItem(state, payload) {
        const selectedStructure = state.copyVersionStructure.selected;

        const selected = [
            ...selectedStructure,
            ...payload,
        ];

        state.copyVersionStructure.selected = uniqBy(selected, item => item.type + item.id);
    },

    /**
     * @param {*} state
     * @param {Array<{type, id}>} payload
     */
    deselectCopyStructureItem(state, payload) {
        const deselectItems = payload;
        const selectedStructure = state.copyVersionStructure.selected;

        const newSelected = selectedStructure.filter(item => !deselectItems.some(isEntityEqualCurried(item)));

        state.copyVersionStructure.selected = newSelected;
    },

    /**
     * @param {*} state
     * @param {{ id, tpye }|null} payload
     */
    setCopyDest(state, payload) {
        state.copyDest = payload;
    },

    /**
     * @param state
     * @param {boolean} payload
     */
    setSendingCopy(state, payload) {
        state.sendingCopy = payload;
    },

    /**
     * @param state
     * @param {boolean} payload
     */
    setRemovingVersion(state, payload) {
        state.removingVersion = payload;
    },
};

