export default {
    created() {
        this.addYandexMetrikaScript();
    },
    beforeUnmount() {
        this.removeYandexMetrikaScript();
    },
    methods: {
    // eslint-disable-next-line max-statements
        addYandexMetrikaScript() {
            if (process.env.VUE_APP_ENV !== 'production') return;

            const head = document.querySelector('head');
            const script = document.createElement('script');
            const noscript = document.createElement('noscript');
            const div = document.createElement('div');
            const img = document.createElement('img');
            const scriptText = '(function(m,e,t,r,i,k,a){m[i]=m[i]||function(){(m[i].a=m[i].a||[]).push(arguments)};var z = null;m[i].l=1*new Date();for (var j = 0; j < document.scripts.length; j++) {if (document.scripts[j].src === r) { return; }}k=e.createElement(t),a=e.getElementsByTagName(t)[0],k.async=1,k.src=r,a.parentNode.insertBefore(k,a)})(window, document, "script", "https://mc.yandex.ru/metrika/tag.js", "ym");ym(89339237, "init", {clickmap:true,trackLinks:true,accurateTrackBounce:true,webvisor:true});';

            this.script = script;
            script.innerText = scriptText;
            head.appendChild(script);

            img.setAttribute('src', 'https://mc.yandex.ru/watch/89339237');
            img.setAttribute('alt', '');
            img.style.position = 'absolute';
            img.style.left = '-9999px';
            div.appendChild(img);
            noscript.appendChild(div);
            document.body.insertBefore(noscript, document.body.firstElementChild);
        },
        removeYandexMetrikaScript() {
            if (!this.script) return null;

            document.head.removeChild(this.script);
            this.script = null;
        },
    },
};
