<template>
    <div
        v-if="isShowPopup"
        class="CookiesPopup">
        <h3 class="CookiesPopup__Title">
            Мы ценим вашу конфиденциальность
        </h3>
        <p class="CookiesPopup__Description">
            Нажимая кнопку «Принять» или продолжая пользоваться веб-сайтом, Вы соглашаетесь с
            <SbsLink
                v-bind="linkData"
                href="https://auth.sberuniversity.online/cookie.pdf">
                Политикой обработки cookie
            </SbsLink>.
            Вы можете отказаться от обработки cookie в соответствии с пунктом
            <SbsLink
                v-bind="linkData"
                href="https://auth.sberuniversity.online/cookie.pdf#page=2">
                5 Политики
            </SbsLink>.
        </p>
        <SbsButton
            class="CookiesPopup__ConfirmBtn"
            backgroundTheme="gradient-green"
            colorTheme="white"
            :bordered="false"
            @click="handleAcceptCookies">
            Принять
        </SbsButton>
        <InlineSvg
            class="CookiesPopup__CloseBtn"
            :src="require('@/assets/svg/icon-close.svg')"
            @click="closePopup" />
    </div>
</template>

<script>
import Cookies from 'js-cookie';
import InlineSvg from 'vue-inline-svg';

const COOKIES_INFO_KEY = 'cookiesInfo';
const COOKIES_ACCEPT_VALUE = 'accept';

const DEFAULT_LINK_DATA = {
    class: 'CookiesPopup__DescriptionLink',
    target: '_blank',
    rel: 'noopener noreferrer',
};

const getIsCookiesAccepted = () => Cookies.get(COOKIES_INFO_KEY) === COOKIES_ACCEPT_VALUE;

export default {
    name: 'CookiesPopup',
    components: {
        InlineSvg,
    },
    data: () => ({
        linkData: DEFAULT_LINK_DATA,
        isShowPopup: !getIsCookiesAccepted(),
    }),
    methods: {
        closePopup() {
            this.isShowPopup = false;
        },
        handleAcceptCookies() {
            Cookies.set(COOKIES_INFO_KEY, COOKIES_ACCEPT_VALUE);
            this.closePopup();
        },
    },

};
</script>

<style lang="scss">
$fontFamily: "Fedra Sans Pro", arial, sans-serif;

.CookiesPopup {
    position: fixed;
    bottom: 32px;
    left: 32px;
    z-index: 110;
    width: 90%;
    max-width: 760px;
    padding: 20px;
    font-family: $fontFamily;
    background: linear-gradient(0deg, hsla(0deg, 0%, 100%, 0.6), hsla(0deg, 0%, 100%, 0.6)), rgba(56, 56, 56, 0.30196078431372547);
    border: 1px solid hsla(0deg, 0%, 100%, 0.16);
    border-radius: 20px;
    backdrop-filter: blur(20px);
}

.CookiesPopup__Title {
    margin: 0.67em 0;
    font-size: 28px;
    line-height: 28px;
}

.CookiesPopup__Description {
    margin: 1em 0;
    font-size: 14px;
    line-height: 28px;
}

.CookiesPopup__ConfirmBtn {
    background-image: linear-gradient(135deg, #32d987, #1bb6ab) !important;
}

.CookiesPopup__DescriptionLink {
    .sbs-link__underline {
        background: $green !important;
    }
}

.CookiesPopup__CloseBtn {
    position: absolute;
    top: 25px;
    right: 25px;
    height: 40px;
    cursor: pointer;

    path {
        color: $gray;
    }

    &:hover {
        path {
            color: $black;
        }
    }
}
</style>

