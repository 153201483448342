<template>
    <SbsSnackbar
        v-bind="mainSnackbarData"
        ref="snackbar"
        class="MainSnackbar"
        @close="$emit('close-toast')">
        <template #prepend>
            <SbsSpinner
                v-if="isTypeTimer && timeout > 0"
                class="MainSnackbar__Spinner"
                v-bind="mainSpinnerData" />
            <img
                v-if="isTypeInfo"
                class="MainSnackbar__Icon"
                src="@/assets/svg/info-snakbar.svg"
                alt="">
        </template>

        <div
            class="MainSnackbar__Content"
            :class="contentClass">
            <h6
                v-if="title && isSizeLarge"
                class="MainSnackbar__Title">
                {{ title }}
            </h6>

            <div
                v-if="message"
                class="MainSnackbar__messageWrapper">
                <p
                    v-html="message"
                    class="MainSnackbar__Message">
                </p>
            </div>

            <SbsLink
                v-if="isLinkVisible"
                class="MainSnackbar__Link"
                :text="link.text"
                colorTheme="white"
                hoverUnderline="white"
                underlineTheme="gray-deep"
                @click="linkGoTo(link.route)">
                <template
                    v-if="link.iconPrepend"
                    #prepend>
                    <InlineSvg :src="require('@/assets/svg/icons8-external-link.svg')" />
                </template>
                <template
                    v-else
                    #append>
                    <InlineSvg :src="require('@/assets/svg/icons8-external-link.svg')" />
                </template>
            </SbsLink>
            <SbsBaseButton
                v-if="isActionButtonVisible"
                class="MainSnackbar__ActionButton"
                :class="actionButton.class"
                v-on="actionButton.on"
                @click="actionButtonClickHandler">
                <InlineSvg
                    v-if="actionButton.iconInline && actionButtonSide === 'before'"
                    class="MainSnackbar__ActionButtonInlineIcon"
                    :class="actionButton.iconInlineClass"
                    :src="actionButton.iconInline" />
                <SbsIcon
                    v-if="actionButton.iconName && actionButtonSide === 'before'"
                    :iconName="actionButton.iconName"
                    :iconGroup="actionButton.iconGroup"
                    :iconSize="actionButton.iconSize" />
                {{ actionButton.text }}
                <InlineSvg
                    v-if="actionButton.iconInline && actionButtonSide === 'after'"
                    class="MainSnackbar__ActionButtonInlineIcon"
                    :class="actionButton.iconInlineClass"
                    :src="actionButton.iconInline" />
                <SbsIcon
                    v-if="actionButton.iconName && actionButtonSide === 'after'"
                    :iconName="actionButton.iconName"
                    :iconGroup="actionButton.iconGroup"
                    :iconSize="actionButton.iconSize" />
            </SbsBaseButton>
        </div>
    </SbsSnackbar>
</template>

<script>
/* eslint-disable vue/no-unused-properties */
import InlineSvg from 'vue-inline-svg';
import { Utils } from '@sbs/uikit-constructor';
import { SbsSnackbar, SbsBaseButton, SbsIcon, SbsSpinner } from '@sbs/uikit-constructor/src/lib/components/index.js';
import { useToast } from 'vue-toastification';

const SECOND_IN_MS = 1000;
const snackbar = useToast();
const SnackbarTypes = {
    success: {
        iconBackgroundTheme: 'gradient-green',
        iconName: 'check',
    },
    error: {
        iconBackgroundTheme: 'gradient-pink',
        iconName: 'close',
    },
    info: {},
    timer: {},
};

export default {
    name: 'MainSnackbar',
    emits: ['close-toast'],
    components: {
        SbsSnackbar,
        SbsBaseButton,
        SbsIcon,
        SbsSpinner,
        InlineSvg,
    },
    data() {
        return {
            snackbarIntervalId: null,
            snackbarTimerId: null,
            spinnerCounter: null,
        };
    },
    computed: {
        actionButtonSide() {
            if (this.actionButton.iconInline) {
                return this.actionButton.side || 'before';
            }

            return this.actionButton.side || 'after';
        },
        isSizeMedium() {
            return this.size === 'medium';
        },
        isSizeLarge() {
            return this.size === 'large';
        },
        isTypeTimer() {
            return this.type === 'timer';
        },
        isTypeInfo() {
            return this.type === 'info';
        },
        isCloseButtonVisible() {
            if (this.isSizeMedium && this.isTypeTimer) {
                return false;
            }

            return this.showCloseButton;
        },
        snackbarClasses() {
            const withBody = this.title && this.message ? 'withBody' : '';

            return [this.snackbarClass, withBody];
        },
        mainSnackbarData() {
            const typeData = SnackbarTypes[this.type] ?? {};

            return {
                ...typeData,
                id: this.toastId,
                showCloseButton: this.isCloseButtonVisible,
                size: this.size,
                width: this.width,
                class: this.snackbarClasses,
            };
        },
        mainSpinnerData() {
            return {
                colorTheme: 'primary',
                content: this.spinnerCounter,
                size: 'medium',
                spinnerContentClass: 'MainSnackbar__SpinnerContent',
                ...this.spinnerData,
            };
        },
        isActionButtonVisible() {
            const { text, iconName, iconInline } = this.actionButton;

            return Boolean(text || iconName || iconInline);
        },
        isLinkVisible() {
            const { text, href } = this.link;

            return Boolean(text || href);
        },
    },
    methods: {
        setSpinnerCounter() {
            this.spinnerCounter = this.timeout / SECOND_IN_MS;
        },
        timerTick() {
            this.spinnerCounter--;
        },
        startTimer() {
            if (!this.snackbarIntervalId) {
                this.snackbarIntervalId = setInterval(
                    this.timerTick,
                    SECOND_IN_MS,
                );
            }

            if (!this.snackbarTimerId) {
                this.snackbarTimerId = setTimeout(
                    this.clearTimer,
                    this.timeout,
                );
            }
        },
        clearTimer() {
            if (!this.snackbarIntervalId && !this.snackbarTimerId) return;

            clearInterval(this.snackbarIntervalId);
            clearTimeout(this.snackbarTimerId);
            this.snackbarIntervalId = null;
            this.snackbarTimerId = null;
        },
        actionButtonClickHandler() {
            this.clearTimer();

            if (this.useProgramCall) {
                this.$refs.snackbar.hide();
            } else {
                this.isSnackbarVisible = false;
            }

            snackbar?.clear();
        },
        linkGoTo(route) {
            this.$router.push(route);
            snackbar?.clear();
        },
    },
    inheritAttrs: false,
    props: {
        actionButton: {
            type: Object,
            default: () => ({
                class: '',
                iconInline: '',
                iconName: '',
                iconGroup: '',
                iconSize: '',
                on: {},
                text: '',
            }),
        },
        /**
         * Включает принудительный показ снекбара, минуя очередь
         */
        force: {
            type: Boolean,
            default: true,
        },
        /**
         * Определяет будет ли снекбар занимать всю доступную ширину
         */
        fullWidth: {
            type: Boolean,
            default: false,
        },
        /**
         * Определяет максимальную ширину, числовые значения приводятся к пикселям, строковые остаются как есть
         */
        maxWidth: {
            type: [Number, String],
            default: null,
        },
        /**
         * Отображаемое сообщение
         */
        message: {
            type: String,
            default: '',
        },
        /**
         * Отображение кнопки закрытия
         */
        showCloseButton: {
            type: Boolean,
            default: true,
        },
        /**
         * Размер снекбара (medium/large)
         */
        size: {
            type: String,
            default: 'medium',
        },
        spinnerData: {
            type: Object,
            default: () => ({}),
        },
        /**
         * Время (в миллисекундах) до того, как снекбар автоматически скроется.
         * Если указать 0, то снекбар не скроется
         */
        timeout: {
            type: Number,
            default: 5000,
        },
        /**
         * Отображаемое заголовок
         */
        title: {
            type: String,
            default: '',
        },
        /**
         * Тип снекбара (success/error/info/timer)
         */
        type: {
            type: String,
            default: '',
            validator: Utils.Validators.isArrayContainsValue(Object.keys(SnackbarTypes)),
        },
        /**
         * Определяет, вызывается ли программно снекбар
         */
        useProgramCall: {
            type: Boolean,
            default: false,
        },
        /**
         * Определяет ширину, числовые значения приводятся к пикселям, строковые остаются как есть
         */
        width: {
            type: [Number, String],
            default: null,
        },
        /**
         * Уникальный идентификатор (по умолчанию проставляется плагином)
         */
        toastId: {
            type: Number,
            default: null,
        },
        snackbarClass: {
            type: String,
            default: null,
        },
        contentClass: {
            type: String,
            default: null,
        },
        link: {
            type: Object,
            default: () => ({
                text: '',
                route: null,
            }),
        },
    },
    mounted() {
        if (this.isTypeTimer) {
            this.setSpinnerCounter();
            this.startTimer();
        }
    },
};
</script>

<style lang="scss">
$fontFamily: "Fedra Sans Pro", arial, sans-serif;

.MainSnackbar {
    opacity: 0.9;

    &__Spinner {
        .MainSnackbar.medium & {
            width: 36px;
            height: 36px;
            margin-right: 10px;
        }

        .MainSnackbar.large & {
            margin-right: 15px;
        }
    }

    &__SpinnerContent {
        padding: 5px;
        color: $white;

        @include text-clamp;
        @include lh-1;

        .MainSnackbar.medium & {
            @include typo-additional-book;
        }

        .MainSnackbar.large & {
            @include typo-primary-book;
        }
    }

    &__Content {
        display: flex;

        .MainSnackbar.medium & {
            align-items: center;
        }

        .MainSnackbar.large & {
            flex-direction: column;
            align-items: flex-start;
        }
    }

    &__Title {
        color: $white;
        font-family: $fontFamily;

        @include typo-subprimary-medium;
    }

    &__messageWrapper {
        .MainSnackbar.withBody & {
            margin-top: 5px;
        }
    }

    &__Message {
        color: $white;
        font-family: $fontFamily;

        @include typo-subprimary-medium;

        .MainSnackbar.medium & {
            @include typo-additional-medium;
        }

        .MainSnackbar.withBody & {
            opacity: 0.6;

            @include typo-additional-book;
        }
    }

    &__Link {
        display: inline-flex;
        gap: 5px;
        align-items: center;
        font-family: $fontFamily;
        cursor: pointer;

        @include typo-subsecondary-book;

        .MainSnackbar.medium & {
            margin-top: 10px;
        }

        .MainSnackbar.large & {
            margin-top: 15px;

            &:not(:first-of-type) {
                margin-top: 5px;
            }
        }
    }

    &__ActionButton {
        display: flex;
        flex-shrink: 0;
        gap: 5px;
        align-items: center;
        width: max-content;
        padding: 0;

        @include typo-secondary-book;

        &:hover {
            @include has-hover {
                color: $gray-deep;

                .MainSnackbar__ActionButtonInlineIcon path {
                    fill: $gray-deep;
                }
            }
        }

        .MainSnackbar.medium &:not(:first-child) {
            margin-left: 35px;
        }

        .MainSnackbar.large &:not(:first-child) {
            margin-top: 15px;
        }
    }

    &__ActionButtonInlineIcon {
        width: 16px;
        height: 16px;

        path {
            fill: $white;
        }
    }
}

.MainSnackbar__Icon {
    margin-right: 15px;
}
</style>
