/**
 * @readonly
 * @enum {string}
 */
export const CopyEntityType = {
    PAGE: 'page',
    SECTION: 'section',
    MODULE: 'module',
    VERSION: 'version',
    SOLUTION: 'solution',
    PROJECT: 'solution',
    DEV_CENTER: 'development_center',
};

export const TypeEntityOrder = {
    [CopyEntityType.PAGE]: 0,
    [CopyEntityType.SECTION]: 1,
    [CopyEntityType.MODULE]: 2,
    [CopyEntityType.VERSION]: 3,
    [CopyEntityType.SOLUTION]: 4,
    [CopyEntityType.DEV_CENTER]: 5,
};
