import axios from 'axios';
import { BASE_URI_DEVELOPMENT_CENTER } from '@/api/CONST.js';

const PREFIX = `${BASE_URI_DEVELOPMENT_CENTER}/statistics`;

/**
 * get solutions reports list
 * @param {string|number} devCenterId
 * @param {object} [params]
 * @param {number|string} [params.page]
 * @param {string} [params.sort]
 * @param {string} [params.type]
 * @returns Promise
 */
export const getSolutionsReports = (devCenterId, params) => axios
    .get(`${BASE_URI_DEVELOPMENT_CENTER}/development-centers/${devCenterId}/reports`, {
        params,
    });

/**
 * download solutions summary report
 * @param {number|string} versionId version id
 * @return {Promise<AxiosResponse>}
 */
export const downloadSolutionsSummaryReports = versionId => axios.get(`${PREFIX}/versions/${versionId}/summary`);

/**
 * download solutions general report
 * @param {number|string} versionId version id
 * @return {Promise<AxiosResponse>}
*/
export const downloadSolutionsStudentReports = versionId => axios.get(`${PREFIX}/versions/${versionId}/student`);

/**
 * download solutions students study progress report
 * @param {number|string} versionId version id
 * @return {Promise<AxiosResponse>}
*/
export const downloadSolutionsStudentsProgressReports = versionId => axios.get(`${PREFIX}/versions/${versionId}/students-study-progress`);

/**
 * download solutions students score report
 * @param {number|string} versionId version id
 * @return {Promise<AxiosResponse>}
*/
export const downloadSolutionsStudentsScoreReport = versionId => axios.get(`${PREFIX}/versions/${versionId}/score`);
