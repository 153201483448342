import axios from 'axios';
import { BASE_URI } from '@/api/CONST.js';
import { AiSessionTypes } from '@/constants/AI.js';

const prefix = `${BASE_URI}/ai`;

/**
 * create AI session
 * @param {string} entityId
 * @param {string} [type]
 * @returns {import('axios').AxiosResponse<{
 *   success: boolean,
 *   data: {
 *     id: number
 *   }
 * }>}
 */
export const createAiSession = (entityId, type = AiSessionTypes.TEXT_BLOCK) => {
    let params = {};

    if (type === AiSessionTypes.TEXT_BLOCK) {
        params = {
            activity_id: entityId,
        };
    }

    if (type === AiSessionTypes.TEST_OR_QUIZ_QUESTION) {
        params = {
            question_id: entityId,
        };
    }

    return axios.post(`${prefix}/sessions`, {
        type,
        params,
    });
};

/**
 * create AI session
 * @param {string|number} sessiondId session id
 * @param {object} data search query data
 * @param {string} data.text search query text
 * @param {string} data.prompt_type query prompt type
 * @returns Promise
 */
export const sendAiSearchQuery = (sessiondId, data) => axios.post(`${prefix}/sessions/${sessiondId}/messages`, data);

/**
 * get AI support problems list
 * @returns Promise
 */
export const getAiSupportProblems = () => axios.get(`${prefix}/dictionaries/problems`);

/**
 * get AI support dialogs themes list
 * @returns Promise
 */
export const getAiSupportDialogThemes = () => axios.get(`${prefix}/dictionaries/dialog-themes`);

/**
 * set like on AI message
 * @param {object} data session and message data
 * @param {string|number} data.messageId session id
 * @param {string|number} data.sessionId message id
 * @returns Promise
 */
export const setLikeAiMessage = ({ sessionId, messageId }) => axios.post(`${prefix}/sessions/${sessionId}/messages/${messageId}/like`);

/**
 * set dislike on AI message
 * @param {object} data session and message data
 * @param {string|number} data.messageId session id
 * @param {string|number} data.sessionId message id
 * @param {object} data.data dislike request data
 * @returns Promise
 */
export const setDislikeAiMessage = ({ sessionId, messageId, data }) => axios.post(`${prefix}/sessions/${sessionId}/messages/${messageId}/dislike`, data);

/**
 * cancel ai message sending
 * @param {object} data session and message data
 * @param {string|number} data.messageId session id
 * @param {string|number} data.sessionId message id
 * @returns Promise
 */
export const canselMeassage = ({ sessionId, messageId }) => axios.delete(`${prefix}/sessions/${sessionId}/messages/${messageId}`);
