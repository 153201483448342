<template>
    <div class="HomeworkInfoPanel">
        <div class="HomeworkInfoPanel__Wrapper">
            <div
                class="HomeworkInfoPanel__Item"
                :class="acceptanceClasses">
                <InlineSvg
                    class="HomeworkInfoPanel__AcceptanceIcon"
                    :src="taskVerificationData.icon" />
                <span>{{ taskVerificationData.title }}</span>
            </div>
            <template v-if="taskTypeData">
                <TextBlock
                    class="HomeworkInfoPanel__ItemDivider"
                    kind="Desktop/font/body/s1-book">
                    •
                </TextBlock>
                <div class="HomeworkInfoPanel__Item">
                    <InlineSvg
                        v-if="taskTypeData.icon"
                        class="HomeworkInfoPanel__Icon"
                        :src="taskTypeData.icon" />
                    <span>{{ taskTypeData.title }}</span>
                </div>
            </template>
            <TextBlock
                class="HomeworkInfoPanel__ItemDivider"
                kind="Desktop/font/body/s1-book">
                •
            </TextBlock>
            <CopyIdWithTooltip
                :id="homeworkId"
                class="HomeworkInfoPanel__Item" />
            <TextBlock
                class="HomeworkInfoPanel__ItemDivider"
                kind="Desktop/font/body/s1-book">
                •
            </TextBlock>
            <div class="HomeworkInfoPanel__Item">
                {{ assignmentLabel }}
            </div>
            <template v-if="withDate">
                <TextBlock
                    class="HomeworkInfoPanel__ItemDivider"
                    kind="Desktop/font/body/s1-book">
                    •
                </TextBlock>
                <EventDatePopup
                    v-if="withDate"
                    v-model="isShowLocationPopup"
                    :eventSchedule="eventSchedule">
                    <SbsLink
                        class="HomeworkInfoPanel__ContentDate"
                        v-bind="linkData"
                        @click.stop="toggleLocationPopup">
                        <template #prepend>
                            <InlineSvg
                                class="HomeworkInfoPanel__DateIcon"
                                :class="contentDateIconClasses"
                                :src="require('@/assets/svg/icons-calendar.svg')" />
                        </template>
                    </SbsLink>
                </EventDatePopup>
            </template>
            <LocationBtn
                v-if="location"
                class="HomeworkInfoPanel__Location"
                :location="location" />
            <HomeworkFilterButtons
                :isShowFilters="isShowFilters"
                :isHaveFilter="isAnyFilterEnabled"
                @toggleShowFilters="toggleShowFilters"
                @toggleSearchInput="toggleSearchInput"
                @goToBlock="goToBlock" />
        </div>
        <SbsInput
            v-if="isSearchOpen"
            v-model="searchValue"
            class="HomeworkInfoPanel__SearchInput"
            colorTheme="primary"
            :placeholder="inputSearchPlaceholder">
            <template #append>
                <SbsButtonIcon
                    class="HomeworkInfoPane__CloseSearch"
                    @click="toggleSearchInput">
                    <InlineSvg
                        class="HomeworkInfoPane__CLoseIcon"
                        :src="require('@/assets/svg/icon-close.svg')" />
                </SbsButtonIcon>
            </template>
        </SbsInput>
        <HomeworkFiltersModal
            v-model:isShow="isShowFilters"
            :homeworkId="homeworkId"
            :isDataLoading="loading"
            :schema="filterSchema"
            :filters="homeworkFilterPopupData"
            :isAnyFilterEnabled="isAnyFilterPopupEnabled"
            :homeworksLength="homeworksLength"
            @filter:change="updateFilter"
            @filters:reset="handleReset"
            @filters:submit="onSubmit" />
    </div>
</template>

<script>
import InlineSvg from 'vue-inline-svg';
import { format } from 'date-fns';
import { ru } from 'date-fns/locale/index.js';
import isEqual from 'lodash/isEqual.js';
import { createNamespacedHelpers } from 'vuex';
import { cloneDeep } from 'lodash';
import axios from 'axios';
import fileIcon from '@/assets/svg/icons8-attach.svg';
import essayIcon from '@/assets/svg/icons8-edit-filled-text.svg';
import { TASK_TYPES, VERIFICATION_FORMAT } from '@/constants/index.js';
import onlineIcon from '@/assets/svg/icons8-computer-play.svg';
import offlineIcon from '@/assets/svg/icons8-map-geomarker.svg';
import autoIcon from '@/assets/svg/icons8-survey 4.svg';
import manualIcon from '@/assets/svg/icons8-filled-speech-bubble-info-emotion.svg';
import useSnackbar from '@/hooks/snackbars.js';
import { getHomeworkBlockList } from '@/api/homeworkApi.js';
import { getHomeworkBlockList as getHomeworkBlocGroupList } from '@/api/homeworkGroupApi.js';
import { homeworkActionsTypes } from '@/admin/store/modules/tasks/actions.js';
import { createCancelHttp } from '@/utils/http.js';
import { ASSIGNMENT_TYPE } from '@/constants/assignments.js';
import CopyIdWithTooltip from '@/components/UI/CopyIdWithTooltip.vue';
import EventDatePopup from '@/admin/components/Events/EventDatePopup.vue';
import LocationBtn from '@/admin/components/common/LocationBtn.vue';
import HomeworkFiltersModal from '@/admin/components/Homework/HomeworkFiltersModal/HomeworkFiltersModal.vue';
import HomeworkFilterButtons from '@/admin/components/Homework/HomeworkFilterButtons.vue';
import TextBlock from '@/components/UI/TextBlock.vue';

const {
    mapActions: mapActionsHomework,
} = createNamespacedHelpers('tasks');

const TASK_TYPES_DATA = {
    [TASK_TYPES.FILE]: {
        title: 'Файл',
        icon: fileIcon,
    },
    [TASK_TYPES.ESSAY]: {
        title: 'Эссе',
        icon: essayIcon,
    },
    [TASK_TYPES.ONLINE]: {
        title: 'Онлайн',
        icon: onlineIcon,
    },
    [TASK_TYPES.OFFLINE]: {
        title: 'Очно',
        icon: offlineIcon,
    },
    [TASK_TYPES.EXTERNAL_TASK]: {
        title: 'Внешняя активность',
    },
};

const VERIFICATION_FORMAT_DATA = {
    [VERIFICATION_FORMAT.AUTO]: {
        title: 'Проверка системой',
        icon: autoIcon,
    },
    [VERIFICATION_FORMAT.MANUAL]: {
        title: 'С преподавателем',
        icon: manualIcon,
    },
};

const FILTERS_SCHEMA = [
    {
        key: 'not_authorized',
        items: [
            {
                label: 'Только ожидающие авторизации',
                value: false,
            },
        ],
    },
    {
        title: 'Статус задания',
        key: 'status',
        items: [
            {
                label: 'Ожидает проверки',
                value: 'need_checking',
            },
            {
                label: 'Нужны доработки',
                value: 'rejected',
            },
            {
                label: 'Сейчас проверяют',
                value: 'on_checking',
            },
            {
                label: 'Материал пройден',
                value: 'accepted',
            },
            {
                label: 'Материал не пройден',
                value: 'failure',
            },
            {
                label: 'Еще не пройдено',
                value: 'initial',
            },
        ],
    },
];

const EXTERNAL_TASK_FILTERS_SCHEMA = [
    {
        key: 'not_authorized',
        items: [
            {
                label: 'Только ожидающие авторизации',
                value: false,
            },
        ],
    },
    {
        title: 'Статус задания',
        key: 'status',
        items: [
            {
                label: 'Ожидает проверки',
                value: 'need_checking',
            },
            {
                label: 'Сейчас проверяют',
                value: 'on_checking',
            },
            {
                label: 'Материал пройден',
                value: 'accepted',
            },
            {
                label: 'Материал не пройден',
                value: 'failure',
            },
            {
                label: 'Еще не пройдено',
                value: 'initial',
            },
        ],
    },
];

export const DEFAULT_HOMEWORK_FILTER_VALUE = {
    not_authorized: false,
    status: [],
    mentors: [],
};

export default {
    name: 'HomeworkInfoPanel',
    components: {
        TextBlock,
        HomeworkFilterButtons,
        HomeworkFiltersModal,
        EventDatePopup,
        InlineSvg,
        CopyIdWithTooltip,
        LocationBtn,
    },
    emits: ['update:modelValue', 'searchHomeworks'],
    data: () => ({
        loading: false,
        isShowFilters: false,
        isSearchOpen: false,
        isShowLocationPopup: false,
        homeworkFilterData: cloneDeep(DEFAULT_HOMEWORK_FILTER_VALUE),
        homeworkFilterPopupData: cloneDeep(DEFAULT_HOMEWORK_FILTER_VALUE),
        homeworksLength: 0,
    }),
    computed: {
        searchValue: {
            get() {
                return this.modelValue;
            },
            set(value) {
                this.$emit('update:modelValue', value);
            },
        },
        inputSearchPlaceholder() {
            if (this.assignamentType === ASSIGNMENT_TYPE.GROUP) return 'Поиск групп по названию и ID';

            if (this.assignamentType === ASSIGNMENT_TYPE.INDIVIDUAL) return 'Поиск учеников по ФИО, логину и ID';

            return 'Поиск';
        },
        filterSchema() {
            if (this.type === TASK_TYPES.EXTERNAL_TASK) return EXTERNAL_TASK_FILTERS_SCHEMA;

            if (this.assignamentType === ASSIGNMENT_TYPE.GROUP) return FILTERS_SCHEMA.filter(item => item.key !== 'not_authorized');

            if (this.assignamentType === ASSIGNMENT_TYPE.INDIVIDUAL) return FILTERS_SCHEMA;

            return [];
        },
        taskTypeData() {
            return TASK_TYPES_DATA[this.type];
        },
        taskVerificationData() {
            return VERIFICATION_FORMAT_DATA[this.homeworkAcceptance];
        },
        // eslint-disable-next-line vue/no-unused-properties
        usersCountText() {
            if (!this.allCount) return '—';

            return `${this.acceptedCount} из ${this.allCount}`;
        },
        eventDate() {
            const schedule = this.eventSchedule;

            if (schedule.length === 0) return null;

            if (schedule.length === 1) return this.formatDate(schedule[0].day);

            return `${this.formatDate(schedule[0].day)} — ${this.formatDate(schedule[schedule.length - 1].day)}`;
        },
        linkData() {
            return {
                text: this.eventDate ? this.eventDate : 'Дата не задана',
                colorTheme: this.eventDate ? 'black' : 'gray',
                hoverUnderline: this.eventDate ? 'primary' : 'gray',
                underlineTheme: this.isShowLocationPopup ? 'primary' : 'gray',
                borderTheme: 'transparent',
            };
        },
        contentDateIconClasses() {
            return [{ 'HomeworkInfoPanel__DateIcon--Active': this.isShowLocationPopup }];
        },
        acceptanceClasses() {
            return [{ 'HomeworkInfoPanel__Item--Manual': this.homeworkAcceptance === 'manual' }];
        },
        isAnyFilterEnabled() {
            return !isEqual(this.homeworkFilterData, DEFAULT_HOMEWORK_FILTER_VALUE);
        },
        isAnyFilterPopupEnabled() {
            return !isEqual(this.homeworkFilterPopupData, DEFAULT_HOMEWORK_FILTER_VALUE);
        },
        assignmentLabel() {
            if (this.assignamentType === ASSIGNMENT_TYPE.GROUP) return 'Групповое';

            if (this.assignamentType === ASSIGNMENT_TYPE.INDIVIDUAL) return 'Индивид. всем';

            return '';
        },
    },
    methods: {
        ...mapActionsHomework({
            fetchHomeworkBlockStudentsData: homeworkActionsTypes.FETCH_HOMEWORK_BLOCK_DATA,
            fetchHomeworkBlockGroupData: homeworkActionsTypes.FETCH_HOMEWORK_BLOCK_GROUP_DATA,
        }),
        updateFilter({ key, value }) {
            this.homeworkFilterPopupData = {
                ...this.homeworkFilterPopupData,
                [key]: value,
            };
            this.getSolutionsLength();
        },
        async handleReset() {
            this.homeworkFilterData = cloneDeep(DEFAULT_HOMEWORK_FILTER_VALUE);
            this.toggleShowFilters();
            await this.applyFilter();
            this.showSuccess('Фильтр сброшен');
        },
        async applyFilter() {
            if (this.assignamentType === ASSIGNMENT_TYPE.GROUP) {
                await this.fetchHomeworkBlockGroupData({
                    homeworkId: this.homeworkId,
                    page: this.$route.query.page,
                    search: this.searchValue,
                    ...this.homeworkFilterData,
                    isReplaceContent: true,
                });
            } else if (this.assignamentType === ASSIGNMENT_TYPE.INDIVIDUAL) {
                await this.fetchHomeworkBlockStudentsData({
                    homeworkId: this.homeworkId,
                    page: this.$route.query.page,
                    search: this.searchValue,
                    ...this.homeworkFilterData,
                    isReplaceContent: true,
                });
            }
        },
        async onSubmit() {
            this.homeworkFilterData = cloneDeep(this.homeworkFilterPopupData);
            this.toggleShowFilters();
            await this.applyFilter();
            this.showSuccess('Фильтр применен');
        },
        toggleLocationPopup() {
            this.isShowLocationPopup = !this.isShowLocationPopup;
        },
        formatDate(date) {
            return String(format(new Date(date), 'dd.LL.yyyy', { locale: ru }));
        },
        toggleSearchInput() {
            this.isSearchOpen = !this.isSearchOpen;
            this.searchValue = '';
        },
        toggleShowFilters() {
            this.homeworkFilterPopupData = cloneDeep(this.homeworkFilterData);
            this.isShowFilters = !this.isShowFilters;
        },
        goToBlock() {
            const { developmentCenterId, solutionId, versionId } = this.$route.params;

            this.$router.push({
                name: 'AdminLongreadUpdateView',
                hash: `#activity-${this.location.activity.id}`,
                params: {
                    developmentCenterId,
                    solutionId,
                    versionId,
                    longreadId: this.location.page.id,
                },
            });
        },
        async getResultCountForStudent() {
            const { data } = await getHomeworkBlockList({
                homeworkId: this.homeworkId,
                page: this.$route.query.page,
                search: this.searchValue,
                ...this.homeworkFilterPopupData,
            }, {
                cancelToken: createCancelHttp('getHomeworkBlockList').token,
            });

            this.homeworksLength = data.meta.total;
        },
        async getResultCountForGroup() {
            const { data } = await getHomeworkBlocGroupList({
                homeworkId: this.homeworkId,
                page: this.$route.query.page,
                search: this.searchValue,
                ...this.homeworkFilterPopupData,
            }, {
                cancelToken: createCancelHttp('getHomeworkBlockList').token,
            });

            this.homeworksLength = data.meta.total;
        },
        async getSolutionsLength() {
            try {
                if (!this.isShowFilters) return;

                this.loading = true;

                if (this.assignamentType === ASSIGNMENT_TYPE.GROUP) {
                    await this.getResultCountForGroup();
                } else if (this.assignamentType === ASSIGNMENT_TYPE.INDIVIDUAL) {
                    await this.getResultCountForStudent();
                }

                this.loading = false;
            } catch (e) {
                if (axios.isCancel(e)) return;

                console.error(e);
                this.loading = false;
            }
        },
        showSuccess(message) {
            this.createSnackbar({
                type: 'success',
                message,
            });
        },
    },
    props: {
        homeworkId: {
            type: [String, Number],
            default: null,
        },
        type: {
            type: String,
            default: null,
        },
        assignamentType: {
            type: String,
            default: null,
        },
        location: {
            type: Object,
            default: null,
        },
        acceptedCount: {
            type: Number,
            default: 0,
        },
        allCount: {
            type: Number,
            default: 0,
        },
        withDate: {
            type: Boolean,
            default: false,
        },
        eventSchedule: {
            type: Array,
            default: () => [],
        },
        homeworkAcceptance: {
            type: String,
            default: 'auto',
        },
        modelValue: {
            type: String,
            default: '',
        },
    },
    setup() {
        const { createSnackbar } = useSnackbar();

        return {
            createSnackbar,
        };
    },
};
</script>

<style lang="scss">
.HomeworkInfoPanel__Wrapper {
    display: flex;
    align-items: center;
}

.HomeworkInfoPanel__Item {
    display: flex;
    gap: 5px;
    align-items: center;
    color: #74777d;

    @include typo-secondary-book;

    .CopyIdWithTooltip__Wrapper {
        color: #74777d;
    }
}

.HomeworkInfoPanel__Item--Manual {
    color: #0066ff;

    @include typo-secondary-demi;
}

.HomeworkInfoPanel__ContentDate {
    gap: 7px;

    @include typo-secondary-book;
}

.HomeworkInfoPanel__DateIcon--Active {
    color: #0066ff;
}

.HomeworkInfoPanel__AcceptanceIcon {
    width: 20px;
    height: 20px;
}

.HomeworkInfoPanel__Icon {
    width: 20px;
    height: 20px;

    path {
        fill: currentColor;
    }
}

.HomeworkInfoPanel__ItemTitle {
    font-weight: 450;
}

.HomeworkInfoPanel__SearchInput {
    width: 100%;
    margin-top: 10px;
}

.HomeworkInfoPane__CloseSearch {
    flex-shrink: 0;
    padding-left: 10px;
    color: #c4c6cd;

    &:hover {
        color: #7a7b80;
    }
}

.HomeworkInfoPane__CLoseIcon {
    width: 20px;
    height: 20px;
}

.HomeworkInfoPanel__SearchButtonIcon {
    margin-right: 10px;
    cursor: pointer;
}

.HomeworkInfoPanel__ActionButton {
    border-radius: 10px;
}

.HomeworkInfoPanel__ItemDivider {
    margin: 0 10px;
    color: #bec4cf;
}

.HomeworkInfoPanel__Location {
    margin-left: 15px;
}
</style>
