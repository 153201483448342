import { defineAsyncComponent } from 'vue';
import { createRouter, createWebHistory } from 'vue-router';
import EventBus from '@/utils/EventBus.js';
// eslint-disable-next-line import/no-cycle
import adminRoutes from '@/admin/router/index.js';
import store from '@/store/index.js';
import { authAndCenterIdGuard, urlGuard } from '@/router/guards.js';
import RootView from '@/views/RootView.vue';

const router = createRouter({
    history: createWebHistory(),
    base: process.env.BASE_URL,
    routes: [
        ...(process.env.VUE_APP_ENV === 'development'
            ? [
                {
                    path: '/test',
                    component: defineAsyncComponent(() => import('@/views/Test.vue')),
                    meta: { test: true },
                },
            ]
            : []),

        {
            path: '/',
            component: RootView,
            children: adminRoutes,
        },

        {
            path: '/:pathMatch(.*)*',
            redirect: {
                name: 'AdminProjectsList',
            },
        },
    ],
    scrollBehavior(to, from) {
        if (to.path !== from.path && !to.hash) {
            return { top: 0 };
        }
    },
});

router.beforeEach((to, from, next) => {
    store.dispatch('globals/setPreviousPageRoute', from);

    EventBus.$emit('route:change', to, from);

    return next();
});

router.beforeEach(urlGuard);
router.beforeEach(authAndCenterIdGuard);

export default router;
