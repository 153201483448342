export const solutionsStructureState = {
    structure: {},
    error: '',
    loading: false,
    removedModuleIndex: 0,
    removedSectionIndex: 0,
    removedPageIndex: 0,
    accessDataForRestore: null,
    lastModuleCreatedId: null,
    selectedEntities: [],
    copyEntities: [],
    copyDest: null,
    copyCurrent: null,
    sendingCopy: false,
    confirmCopyToVersion: null,
    confirmSuccess: null,
    confirmCancel: null,
};
