/**
 * @readonly
 * @enum {string}
 */
export const AiMessagesPromptTypes = {
    IMPROVE_WRITING: 'improve_writing',
    CORRECT_WRITING: 'correct_writing',
    MAKE_SHORTER: 'make_shorter',
    MAKE_LONGER: 'make_longer',
    SUMMARIZE: 'summarize',
    GENERATE_INCORRECT_ANSWERS: 'generate_incorrect_answers',
    GENERATE_IMAGE: 'generate_image',
    GENERATE_TASK: 'generate_task',
};

/**
 * @readonly
 * @enum {string}
 */
export const AiSessionTypes = {
    TEXT_BLOCK: 'text_block',
    TEST_OR_QUIZ_QUESTION: 'test_or_quiz_question',
};
