import { getUser, logout } from '@/api/userApi.js';
import { setSentryUser } from '@/services/sentry.js';

export const userMutationTypes = Object.freeze({
    SET_USER: '[user] set data',
});

export const userActionTypes = Object.freeze({
    GET_USER: '[user] get data',
    LOGOUT: '[user] log out',
});

export const userGetterTypes = Object.freeze({
    GET_USER: '[user] get data',
    GET_LOGGED: '[user] get logged',
    GET_USER_LOADING: '[user] get user loading',
    GET_ADMIN_ROLE: '[user] get admin role',
});

export default {
    namespaced: true,
    state: {
        user: null,
        loggedIn: false,
        loading: false,
    },
    mutations: {
        [userMutationTypes.SET_USER](state, { user, loggedIn }) {
            state.user = user;
            state.loggedIn = loggedIn;
        },
        setUserLoading(state, loading) {
            state.loading = loading;
        },
    },
    getters: {
        [userGetterTypes.GET_USER](state) {
            return state.user ?? {};
        },
        [userGetterTypes.GET_LOGGED](state) {
            return state.loggedIn;
        },
        [userGetterTypes.GET_USER_LOADING](state) {
            return state.loading;
        },
        [userGetterTypes.GET_ADMIN_ROLE](state) {
            return Boolean(state.user?.roles?.includes('admin'));
        },
    },
    actions: {
        async [userActionTypes.GET_USER]({ commit, dispatch }) {
            try {
                commit('setUserLoading', true);

                const { data } = await getUser();
                const {
                    data: user,
                    logged_in: loggedIn,
                } = data;

                commit(userMutationTypes.SET_USER, {
                    user,
                    loggedIn,
                });

                setSentryUser(user.id);

                dispatch('reports/initReportChanel', { userId: user.id }, {
                    root: true,
                });

                dispatch('reports/initSolutionsImportChanel', { userId: user.id }, {
                    root: true,
                });

                dispatch('student/initImportChannel', { userId: user.id }, {
                    root: true,
                });

                dispatch('solutions/initCopyChannel', { userId: user.id }, {
                    root: true,
                });

                return Promise.resolve(loggedIn);
            } catch (error) {
                const errorText = '[user] error while get data from api';

                console.error(errorText, error);

                return Promise.reject(errorText);
            } finally {
                commit('setUserLoading', false);
            }
        },
        async [userActionTypes.LOGOUT]({ commit }) {
            try {
                await logout();
                commit(userMutationTypes.SET_USER, {
                    user: null,
                    loggedIn: false,
                });

                return Promise.resolve();
            } catch (error) {
                const errorText = '[user] error while logout';

                console.error(errorText, error);

                return Promise.reject(errorText);
            }
        },
    },
};
